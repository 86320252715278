<ng-template #icon let-i>
  @if (i) {
    @switch (i.type) {
      @case ('icon') {
        <i
          class="sidebar-nav__item-icon"
          nz-icon
          [nzType]="i.value"
          [nzTheme]="i.theme"
          [nzSpin]="i.spin"
          [nzTwotoneColor]="i.twoToneColor"
          [nzIconfont]="i.iconfont"
          [nzRotate]="i.rotate"></i>
      }
      @case ('iconfont') {
        <i class="sidebar-nav__item-icon" nz-icon [nzIconfont]="i.iconfont"></i>
      }
      @case ('img') {
        <img [src]="i.value" class="sidebar-nav__item-icon sidebar-nav__item-img" />
      }
      @case ('svg') {
        <span class="sidebar-nav__item-icon sidebar-nav__item-svg" [innerHTML]="i.value"></span>
      }
      @default {
        <i class="sidebar-nav__item-icon {{ i.value }}"></i>
      }
    }
  }
</ng-template>
<ng-template #tree let-ls>
  @for (i of ls; track i) {
    @if (i._hidden !== true) {
      <li class="sidebar-nav__item" [style.borderWidth]="0" [class.sidebar-nav__selected]="i._selected" [class.sidebar-nav__open]="i.open">
        <!-- link -->
        @if (i.children.length === 0) {
          <ng-template #linkContent>
            @if (i._needIcon) {
              @if (!collapsed) {
                <ng-template [ngTemplateOutlet]="icon" [ngTemplateOutletContext]="{ $implicit: i.icon }" />
              }
              @if (collapsed) {
                <span nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="i.text">
                  <ng-template [ngTemplateOutlet]="icon" [ngTemplateOutletContext]="{ $implicit: i.icon }" />
                </span>
              }
            }
            <span class="sidebar-nav__item-text" [innerHTML]="i._text" [attr.title]="i.text"></span>
            <ng-container *ngTemplateOutlet="badge" />
          </ng-template>
          @if (i.externalLink) {
            <a
              [attr.href]="i.externalLink"
              [target]="i.target ?? '_self'"
              (click)="to(i)"
              [attr.data-id]="i._id"
              class="sidebar-nav__item-link"
              [ngClass]="{ 'sidebar-nav__item-disabled': i.disabled }"
              (mouseenter)="closeSubMenu()">
              <ng-container *ngTemplateOutlet="linkContent" />
            </a>
          } @else {
            <a
              [routerLink]="i.link ? [i.link] : undefined"
              [queryParams]="i.queryParams"
              [routerLinkActive]="'sidebar-nav__item-active'"
              queryParamsHandling="merge"
              (click)="to(i)"
              [attr.data-id]="i._id"
              class="sidebar-nav__item-link"
              [ngClass]="{ 'sidebar-nav__item-disabled': i.disabled }"
              (mouseenter)="closeSubMenu()">
              <ng-container *ngTemplateOutlet="linkContent" />
            </a>
          }
        }
        <!-- has children link -->
        @if (i.children.length > 0) {
          <a (click)="toggleOpen(i)" (mouseenter)="showSubMenu($event, i)" class="sidebar-nav__item-link">
            <ng-template [ngTemplateOutlet]="icon" [ngTemplateOutletContext]="{ $implicit: i.icon }" />
            <span class="sidebar-nav__item-text" [innerHTML]="i._text" [attr.title]="i.text"></span>
            <i class="sidebar-nav__sub-arrow"></i>
            <ng-container *ngTemplateOutlet="badge" />
          </a>
        }
        <ng-template #badge>
          <!-- badge -->
          @if (i.badge) {
            <nz-tag
              class="nav-badge"
              [nzColor]="i.badge_tag > 0 ? 'orange' : i.badge_snooze == i.badge ? 'lightgrey' : '#1890ff'"
              nz-tooltip="{{ i.badge }}"
              nzTooltipPlacement="bottom"
              [ngStyle]="{ borderRadius: '10px', margin: '0', position: 'absolute', right: '10px' }">
              {{ getOverflowCount(i.badge) }}
            </nz-tag>
          }
        </ng-template>
        @if (i.children.length > 0) {
          <ul class="sidebar-nav sidebar-nav__sub sidebar-nav__depth{{ i._depth }}">
            <ng-template [ngTemplateOutlet]="tree" [ngTemplateOutletContext]="{ $implicit: i.children }" />
          </ul>
        }
      </li>
    }
  }
</ng-template>
<ul class="sidebar-nav">
  @for (group of list; track group) {
    @if (group.group) {
      <li class="sidebar-nav__item sidebar-nav__group-title">
        <span [innerHTML]="group._text"></span>
      </li>
    }
    <ng-template [ngTemplateOutlet]="tree" [ngTemplateOutletContext]="{ $implicit: group.children }" />
  }
</ul>
